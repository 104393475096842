import { Popover, Transition } from '@headlessui/react';
import useTracker from 'core/hooks/use-tracker';
import styles from './Infos.module.css';
import React, { Fragment } from 'react';
import { Info } from 'lib/Icons';

interface Props {
  readonly label: string;
  readonly description: string;
}

const Infos: React.FC<Props> = ({ label, description }) => {

  const { trackTooltip } = useTracker();

  return (
    <Popover className={styles.root}>
      <Popover.Button as={Fragment}>
        <Info className={styles.info} onClick={() => trackTooltip(label)} />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className={styles.panel}>
          <div className={styles.descriptionContainer}>
            <div className={styles.description}>
              {description}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default Infos;
