import { IAuth } from './auth.model';

const authDefault: IAuth = {
  id: undefined,
  lastname: undefined,
  firstname: undefined,
  signature: undefined,
};

export default authDefault;
