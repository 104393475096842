import useScopedTranslation from 'core/hooks/use-scoped-translation';
import styles from './Question.module.css';
import Option from './Option';
import React from 'react';

interface Props {
  readonly id: string;
  readonly from: string;
  readonly label: string;
  readonly answer?: {
    readonly questionId: string;
    readonly optionId: string;
  };
  readonly imageUrl: string;
  readonly options: {
    readonly id: string;
    readonly label: string;
  }[];
  readonly topicLabel: string;
  readonly onClick: (...args: any) => void;
}

const Question: React.FC<Props> = ({ id, from, label, answer, onClick, options, imageUrl, topicLabel }) => {

  const { t } = useScopedTranslation('COMPONENTS', 'SLIDE_QUESTION');

  const onSelect = (optionId: string) => {
    onClick({ questionId: id, optionId });
  };

  return (
    <div className={styles.root}>

      <div className={styles.container}>

        <div className={styles.left}>
          <h5>{topicLabel}</h5>
          <h4>{t('FROM')} {from}</h4>
          <h3>{label}</h3>
          <div className={styles.image}>
            <img src={'https://' + imageUrl} alt="" />
          </div>
        </div>

        <div className={styles.right}>
          <div className={styles.options}>
            {options.map(({ id, label }) => (
              <Option
                id={id}
                key={id}
                label={label}
                onSelect={() => onSelect(id)}
                selected={answer?.optionId === id}
              />
            ))}
          </div>
        </div>

      </div>

    </div>
  );
};

export default Question;
