import profileDefault from './profile.default';
import * as actions from './profile.actions';
import { IProfile } from './profile.model';
import { AnyAction } from 'redux';

const ProfileReducer = (state: IProfile = profileDefault, action: AnyAction): IProfile => {
  switch (action.type) {
    case actions.PATCH_PROFILE_STATE: {
      const { profileState } = action;
      return { ...state, ...profileState };
    }
    case actions.PATCH_PROFILE_SKILLS: {
      const {skills} = action;
      return { ...state, skills: {...state.skills, ...skills}};
    }
    case actions.PATCH_PROFILE_ERROR: {
      const {error} = action;
      return { ...state, error};
    }
    default: {
      return state;
    }
  }
};

export default ProfileReducer;
