import { AuthInitials, AuthState } from 'core/store/auth/auth.selectors';
import useScopedTranslation from 'core/hooks/use-scoped-translation';
import { ProfileState } from 'core/store/profile/profile.selectors';
import { Navigate, useLocation } from 'react-router-dom';
import Legends from 'components/ProfileRow/Legends';
import DownloadPdf from 'components/DownloadPdf';
import useTracker from 'core/hooks/use-tracker';
import ProfileRow from 'components/ProfileRow';
import ModalReset from 'components/ModalReset';
import AnimatedPage from 'lib/AnimatedPage';
import styles from './Profile.module.css';
import { useSelector } from 'react-redux';
import logo from 'assets/images/logo.png';
import React, { useEffect } from 'react';
import Chart from 'lib/Chart';

const Profile: React.FC = () => {

  const { search } = useLocation();
  const { trackProfile } = useTracker();
  const initials = useSelector(AuthInitials);
  const { firstname, lastname } = useSelector(AuthState);
  const { t } = useScopedTranslation('PAGES', 'PROFILE');
  const skills = useSelector(ProfileState).skills?.items?.reverse();

  const colors = ['text-cyan-500', 'text-orange-500', 'text-lime-500'];

  useEffect(() => {
    if (!skills?.length) return;
    trackProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!skills?.length) return <Navigate to={'/' + search} />;

  return (
    <AnimatedPage>

      <div className={styles.root} data-pdf='ready'>
        <div className={styles.resume}>
          <div className={styles.infos}>
            <h2>{firstname} {lastname}</h2>
            <DownloadPdf />
            <ModalReset />
          </div>
          <div className={styles.chartContainer}>
            <div className={styles.initiales}>{initials}</div>
            <Chart scores={skills.map(({ percentage }, i) => ({ percentage, color: colors[i] }))} />
          </div>
        </div>
        <div className={styles.head}>
          <h2 className={styles.title}>{t('TITLE')}</h2>
          <div className={styles.textScreen}>{t('TEXT_SCREEN')}</div>
          <div className={styles.textPrint}>{t('TEXT_PRINT')}</div>
        </div>
        <div className={styles.content}>
          {skills?.map((item, i) => <ProfileRow {...item} className={colors[i]} key={i} />)}
          <Legends />
        </div>
      </div>

      <img src={logo} alt='' className={styles.logo} />

    </AnimatedPage>
  );
};

export default Profile;
