import { IConfig } from './config.model';

const configDefault: IConfig = {
  skills: {
    topics: [],
  },
  error: undefined,
  fetched: false,
};

export default configDefault;
