import useScopedTranslation from 'core/hooks/use-scoped-translation';
import usePersistedState from 'core/hooks/usePersistedState';
import { AuthState } from 'core/store/auth/auth.selectors';
import React, { ChangeEvent, useState } from 'react';
import styles from './PiwikProPrivacy.module.css';
import { getWindow } from 'core/utils/window';
import Checkbox from 'components/Checkbox';
import { useSelector } from 'react-redux';
import { Close, Cookie } from '../Icons';
import Script from 'react-script-tag-18';

const PiwikProPrivacy: React.FC = () => {

  const window = getWindow();
  const { id } = useSelector(AuthState);
  const { t } = useScopedTranslation('COMPONENTS', 'PIWIK_PRO_PRIVACY');

  const [checked, setChecked] = useState<boolean>();
  const [open, setOpen] = usePersistedState<boolean>('cookie-banner' + id, true);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  const toggleOpen = () => {
    setOpen(s => !s);
  };

  const onLoadedScript = () => {
    setChecked((document.getElementById('_stg_optout_checkbox') as HTMLInputElement)?.checked);
  };

  return (
    <div className={styles.root}>
      <div className={styles.container} data-open={open}>
        <div className={styles.left}>
          {open
            ? <Close className={styles.icon} onClick={toggleOpen} />
            : <Cookie className={styles.icon} onClick={toggleOpen} />
          }
        </div>
        <div className={styles.content} data-open={open}>
          <div id="_stg_opt_out_iframe_content" style={{ display: 'none' }}>
            <p>{t('TEXT')}</p>
            <Checkbox
              onChange={onChange}
              id="_stg_optout_checkbox"
              label={checked ? t('OPTED_OUT') : t('OPTED_IN')}
              onClick={() => window.storeUserOptOutPreferences()}
            />
            <label id="_stg_optout_checkbox_label" htmlFor="_stg_optout_checkbox" style={{ display: 'none' }} />
          </div>
            <Script onLoad={onLoadedScript} src={process.env.REACT_APP_PIWIKPRO_SCRIPT_URL!} />
        </div>
      </div>
    </div>
  );
};

export default PiwikProPrivacy;
