import { SVGProps } from 'react';

const Download = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
    <path fill="currentColor"
          d="M77.5 118.5a12 12 0 0 1 17-17L116 123V40a12 12 0 0 1 24 0v83l21.5-21.5a12 12 0 0 1 17 17l-42 42a12.1 12.1 0 0 1-17 0ZM216 140a12 12 0 0 0-12 12v52H52v-52a12 12 0 0 0-24 0v56a20.1 20.1 0 0 0 20 20h160a20.1 20.1 0 0 0 20-20v-56a12 12 0 0 0-12-12Z" />
  </svg>
);

export default Download;
