import { IConfig } from './config.model';

export const GET_CONFIG_STATE = 'GET_CONFIG_STATE';
export const PATCH_CONFIG_STATE = 'PATCH_CONFIG_STATE';
export const PATCH_CONFIG_ERROR = 'PATCH_CONFIG_ERROR';

export const GetConfigState = () => ({
  type: GET_CONFIG_STATE,
});

export const PatchConfigState = (configState: Partial<IConfig>) => ({
  type: PATCH_CONFIG_STATE,
  configState,
});

export const PatchConfigError = (error: Error) => ({
  type: PATCH_CONFIG_ERROR,
  error,
});
