import { GET_CONFIG_STATE, PatchConfigError, PatchConfigState } from './config.actions';
import { put, takeLatest, select } from 'redux-saga/effects';
import { getConfigurations } from 'core/domain/api.domain';
import { AuthState } from '../auth/auth.selectors';
import { IAuth } from '../auth/auth.model';

export function* GetConfigStateSaga() {
  try {
    const { id }: IAuth = yield select(AuthState);
    const { data } = yield getConfigurations(id!);
    yield put(PatchConfigState(data));
    yield put(PatchConfigState({fetched: true}));
  } catch (e) {
    yield put(PatchConfigError(e as Error))
  }
}

export function* ConfigWatcher() {
  yield takeLatest(GET_CONFIG_STATE, GetConfigStateSaga);
}
