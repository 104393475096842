import { ITopic } from 'core/models/topic.model';
import { IConfig } from './config.model';
import { IStore } from '../store.model';

export const ConfigState = (store: IStore): IConfig => {
  return store.config;
};

export const ConfigSkillsTopics = (store: IStore): ITopic[] => {
  return  store.config.skills.topics;
};

export const ConfigSkillsTopic = (index: number = 0) => (store: IStore): ITopic => {
  return  store.config.skills.topics[index];
};

