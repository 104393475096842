import { Check } from 'components/Icons';
import styles from './Checkbox.module.css';
import React from 'react';

type Props = Omit<React.HTMLAttributes<HTMLInputElement>, 'label'>
  & React.RefAttributes<HTMLInputElement>
  & { label: React.ReactNode; };

const Checkbox = React.forwardRef<HTMLInputElement, Props>(({ className, label, ...props }, ref) => {
  return (
    <label className={styles.root}>
      <div className={`${styles.field} ${className}`}>
        <input {...props} ref={ref} type="checkbox" className={styles.input} hidden />
        <div className={styles.box}>
          <Check className={styles.mark} />
        </div>
      </div>
      <div className={styles.label}>{label}</div>
    </label>
  );
});

export default Checkbox;
