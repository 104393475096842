import styles from './Topic.module.css';
import React from 'react';

interface Props {
  readonly label: string;
  readonly imageUrl: string;
}

const Topic: React.FC<Props> = ({ label, imageUrl }) => {

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <h1>{label}</h1>
      </div>
      <div className={styles.right}>
        <img alt={label} src={'https://' + imageUrl} />
      </div>
    </div>
  );
};

export default Topic;
