import useScopedTranslation from 'core/hooks/use-scoped-translation';
import styles from './Score.module.css';
import Infos from '../Infos';
import React from 'react';

interface Props {
  readonly id: string;
  readonly label: string;
  readonly threshold: number;
  readonly description: string;
}

const Score: React.FC<Props> = ({ label, threshold, description }) => {

  const { t } = useScopedTranslation('COMMONS', 'LEGENDS')

  const size = () => {
    if (threshold === 0) return '!w-0.5 !p-0';
    if (threshold === 1) return '!w-1/3';
    if (threshold === 2) return '!w-2/3';
    if (threshold === 3) return '!w-full';
  }

  return (
    <div className={styles.root}>
      <div className={styles.head}>
        <h6 className={styles.title}>{label}</h6>
        <p className={styles.description}>{description}</p>
        <Infos label={label} description={description} />
      </div>
      <div className={styles.progressContainer}>
        <div className={styles.background} />
        <div className={styles.backgroundContent}>
          <span>{t('LEGEND_' + threshold)}</span>
        </div>
        <div className={`${styles.progress} ${size()}`} />
        <div className={`${styles.progressContent} ${size()}`}>
          <span>{!!threshold && t('LEGEND_' + threshold)}</span>
        </div>
        <div className={styles.lines}>
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  );
};

export default Score;
