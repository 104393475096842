import { IAnswer } from 'core/models/answer.model';
import { IAnswers } from './answers.model';

export const SEND_SKILLS_ANSWERS = 'SEND_SKILLS_ANSWERS';
export const PATCH_ANSWERS_STATE = 'PATCH_ANSWERS_STATE';
export const ADD_OR_UPDATE_SKILLS_ANSWER = 'ADD_OR_UPDATE_SKILLS_ANSWER';

export const PatchAnswersState = (answersState: Partial<IAnswers>) => ({
  type: PATCH_ANSWERS_STATE,
  answersState,
});

export const AddOrUpdateSkillsAnswer = (answer: IAnswer) => ({
  type: ADD_OR_UPDATE_SKILLS_ANSWER,
  answer,
});

export const SendSkillsAnswers = () => ({
  type: SEND_SKILLS_ANSWERS,
});
