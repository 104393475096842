import configDefault from './config.default';
import * as actions from './config.actions';
import { IConfig } from './config.model';
import { AnyAction } from 'redux';

const ConfigReducer = (state: IConfig = configDefault, action: AnyAction): IConfig => {
  switch (action.type) {
    case actions.PATCH_CONFIG_STATE: {
      const { configState } = action;
      return { ...state, ...configState };
    }
    case actions.PATCH_CONFIG_ERROR: {
      const { error } = action;
      return { ...state, error };
    }
    default: {
      return state;
    }
  }
}

export default ConfigReducer;
