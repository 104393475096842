import styles from './Button.module.css';
import React, { ReactNode } from 'react';

type Variant = 'right' | 'left';

interface Props {
  readonly variant?: Variant;
  readonly disabled?: boolean;
  readonly onClick?: () => void;
  readonly className?: string;
  readonly loading?: boolean;
  readonly loader?: ReactNode;
}

const Button: React.FC<Props> = ({ onClick, variant, disabled, className, children, loader, loading }) => {

  const classes = [
    styles.root,
    disabled ? styles.disabled : '',
    variant === 'left' ? styles.left : '',
    variant === 'right' ? styles.right : '',
    loading ? styles.loading : '',
    className,
  ];

  return (
    <button onClick={onClick} className={classes.join(' ')}>
      <span>{children}</span>
      <div className={styles.loader}>{loading && loader}</div>
    </button>
  );
};

export default Button;
