import { useState, useEffect, Dispatch, SetStateAction } from 'react';

const usePersistedState = <T>(key: string, initialState: T): [T, Dispatch<SetStateAction<T>>, (() => void)] => {

  const [state, setState] = useState<T>(initialState);
  const [mounted, setMounted] = useState<boolean>(false);

  const resetState = () => setState(initialState);

  useEffect(() => {
    const persistedState = localStorage.getItem(key);
    setState(persistedState ? JSON.parse(persistedState) : initialState);
    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!mounted) return;
    localStorage.setItem(key, JSON.stringify(state));
  }, [state, key, mounted]);

  return [state, setState, resetState];
}

export default usePersistedState;
