import styles from './Loader.module.css';
import React from 'react';

type Size = 'xs' | 'sm' | 'md';

interface Props {
  readonly size?: Size;
  readonly white?: boolean;
  readonly className?: string;
}

const Loader: React.FC<Props> = ({ className, size = 'md', white = false }) => {
  return (
    <div className={`
      ${styles.root}
      ${white ? styles.white : ''}
      ${size === 'xs' ? styles.xs : ''}
      ${size === 'sm' ? styles.sm : ''}
      ${size === 'md' ? styles.md : ''}
      ${className}`}>
      <svg className={styles.svg} viewBox="25 25 50 50">
        <circle className={styles.circle} cx="50" cy="50" r="20" />
      </svg>
    </div>
  );
};

export default Loader;
