import { ReactComponent as HomeMobile } from 'assets/vectors/home_mobile.svg';
import { ReactComponent as HomeRight } from 'assets/vectors/home_right.svg';
import { ReactComponent as HomeLeft } from 'assets/vectors/home_left.svg';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import useScopedTranslation from 'core/hooks/use-scoped-translation';
import { ProfileState } from 'core/store/profile/profile.selectors';
import PiwikProPrivacy from 'components/PiwikProPrivacy';
import useTracker from 'core/hooks/use-tracker';
import AnimatedPage from 'lib/AnimatedPage';
import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import styles from './Home.module.css';
import { Clock } from 'lib/Icons';
import Button from 'lib/Button';

const Home: React.FC = () => {

  const navigate = useNavigate();
  const { search } = useLocation();
  const { trackHome } = useTracker();
  const { skills } = useSelector(ProfileState);
  const { t } = useScopedTranslation('PAGES', 'HOME');

  const onClick = () => navigate('/skills/' + search);

  useEffect(() => {
    if (skills?.ended) return;
    trackHome();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (skills?.ended) return <Navigate to={'/profile' + search} />;

  return (
    <AnimatedPage>
      <div className={styles.root}>

        <PiwikProPrivacy />

        {/* DESKTOP: LEFT COLUMN */}
        <div className={styles.desktopLeft}>
          <HomeLeft />
        </div>

        {/* DESKTOP: RIGHT COLUMN */}
        <div className={styles.desktopRight}>
          <HomeRight />
        </div>

        {/* MOBILE: TOP COLUMN */}
        <div className={styles.mobileTop} />

        {/* MOBILE: BOTTOM COLUMN */}
        <div className={styles.mobileBottom}>
          <HomeMobile />
        </div>

        {/* DESKTOP & MOBILE: MIDDLE COLUMN */}
        <div className={styles.middle}>
          <h1 className={styles.hello}>{t('HELLO')}</h1>
          <h2 className={styles.title}>{t('TITLE')}</h2>
          <p className={styles.text}>{t('TEXT')}</p>
          <p className={styles.time}><Clock className="w-5 h-5" />{t('TIME')}</p>
          <Button onClick={onClick}>{t('BUTTON')}</Button>
        </div>

      </div>
    </AnimatedPage>
  );
};

export default Home;
