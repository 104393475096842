import { ConfigSkillsTopics } from 'core/store/config/config.selectors';
import useScopedTranslation from 'core/hooks/use-scoped-translation';
import { AnswersSkills } from 'core/store/answers/answers.selectors';
import { ProfileState } from 'core/store/profile/profile.selectors';
import { Navigate, useLocation } from 'react-router-dom';
import AnimatedPage from 'lib/AnimatedPage';
import { Survey } from 'components/Survey';
import { useSelector } from 'react-redux';
import styles from './Skills.module.css';
import React from 'react';

const Skills: React.FC = () => {

  const { search } = useLocation();
  const answers = useSelector(AnswersSkills);
  const profile = useSelector(ProfileState);
  const topics = useSelector(ConfigSkillsTopics);
  const { t } = useScopedTranslation('PAGES', 'SKILLS');

  if (profile.skills?.ended) return <Navigate to={'/profile' + search} />;

  return (
    <AnimatedPage>
      <div className={styles.root}>
        <div className={styles.header}>
          <h2>{t('TITLE')}</h2>
        </div>
        <Survey answers={answers} topics={topics} />
      </div>
    </AnimatedPage>
  );
};

export default Skills;
