import { batch, Provider, useDispatch, useSelector } from 'react-redux';
import useScopedTranslation from './core/hooks/use-scoped-translation';
import { GetProfileState } from 'core/store/profile/profile.actions';
import { ProfileState } from 'core/store/profile/profile.selectors';
import { ConfigState } from 'core/store/config/config.selectors';
import { PatchAuthState } from 'core/store/auth/auth.actions';
import { PersistGate } from 'redux-persist/integration/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { decode64 } from 'core/helpers/strings.helpers';
import React, { useEffect, useState } from 'react';
import createStoreWithKey from 'core/store/store';
import useQuery from 'core/hooks/use-query';
import Loader from 'lib/Loader';
import Error from 'lib/Error';

const Initializer: React.FC = ({ children }) => {

  const id = useQuery('id');
  const lastname = useQuery('lastName');
  const firstname = useQuery('firstName');
  const signature = useQuery('signature');
  const { t } = useScopedTranslation('COMMONS', 'ERRORS');

  const { store, persist } = createStoreWithKey(id || 'test');

  const LoadData: React.FC = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();
    const profile = useSelector(ProfileState);
    const config = useSelector(ConfigState);

    const [ready, setReady] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
      if (ready) return;

      const onInit = async () => {

        if (!lastname || !firstname) {
          setError(true);
          return;
        }

        batch(() => {
          dispatch(PatchAuthState({ id }));
          dispatch(PatchAuthState({ signature }));
          dispatch(PatchAuthState({ lastname: decode64(lastname) }));
          dispatch(PatchAuthState({ firstname: decode64(firstname) }));
          dispatch(GetProfileState());
        });
      };

      onInit().then();

    }, [ready, setError, dispatch]);

    useEffect(() => {
      if (profile.error || config.error) setError(true);
      setReady(profile.fetched && (profile.skills?.ended || config.fetched));
      if (profile.fetched && profile.skills?.ended) navigate('/profile/' + search);

    }, [config, navigate, profile, search]);

    if (error) return (
      <div className="w-full h-screen flex items-center justify-center">
        <Error title={t('ACCESS') as string} />
      </div>
    );

    if (!ready) return (
      <div className="w-full h-screen flex items-center justify-center">
        <Loader className="text-rose-500" size="md" />
      </div>
    );

    return <>{children}</>;
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persist}>
        <LoadData />
      </PersistGate>
    </Provider>
  );
};

export default Initializer;
