import React from 'react';

interface Props {
  readonly scores: {
    readonly color: string;
    readonly percentage: number;
  }[];
}

const Chart: React.FC<Props> = ({ scores }) => {

  const radius = 100;
  const circleCircumference = radius * Math.PI * 2;
  const maxPercentage = Math.max(...scores.map(({ percentage }) => percentage));

  return (
    <svg
      width='100%'
      height='100%'
      viewBox={`0 0 ${radius * 4} ${radius * 4}`}
    >
      <defs>
        <mask id='stripes' width="200%" height="200%" x="-50%" y="-50%">
          <rect width="100%" height="100%" fill='white' />
          {scores.map((_, i) => (
            <g
              origin={`${radius * 2} ${radius * 2}`}
              transform={`rotate(${360 / scores.length * i} ${radius * 2} ${radius * 2})`}
            >
              <rect
                x={radius * 2}
                width={radius * 2 / 20}
                height={radius * 2}
                fill='black'
                strokeWidth="2"
                stroke="black"
              />
            </g>
          ))}
        </mask>
      </defs>

      <g
        width={radius * 4} height={radius * 4}
        origin={`${radius * 2} ${radius * 2}`}
        mask="url(#stripes)">
      {scores.map(({ percentage, color }, i) => (
        <g
          key={i}
          className={color}
          origin={`${radius * 2} ${radius * 2}`}
          transform={`rotate(${360 / scores.length * i - 90} ${radius * 2} ${radius * 2})`}
        >

          <circle
            cx='50%'
            cy='50%'
            r={radius}
            fill='white'
            stroke='currentColor'
            strokeDasharray={circleCircumference}
            strokeWidth={(percentage + 4) / (maxPercentage + 4) * 50 + '%'}
            strokeDashoffset={circleCircumference / scores.length * (scores.length - 1)}
          />
        </g>
      ))}
      </g>

      <circle
        cx='50%'
        cy='50%'
        r={radius}
        fill='#e6eaee'
      />

    </svg>
  );
};

export default Chart;
