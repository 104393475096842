import { getCurrentLanguage, resources } from '../i18n';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { StringMap } from 'i18next';

const useScopedTranslation = (namespace: string, prefix?: string) => {

  const namespaces = Object.keys(resources[getCurrentLanguage()]);

  const { t } = useTranslation(namespaces);

  const start = prefix ? `${prefix}.` : ``;

  return {
    t: (path: string, params?: StringMap) => {
      return path.startsWith('@')
        ? parse(t(path.substring(1), { ...params, ns: 'COMMONS' }))
        : parse(t(start + path, { ...params, ns: namespace }));
    },
  };
};

export default useScopedTranslation;
