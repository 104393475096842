import answersDefault from './answers.default';
import * as actions from './answers.actions';
import { IAnswers } from './answers.model';
import { AnyAction } from 'redux';

const AnswersReducer = (state: IAnswers = answersDefault, action: AnyAction): IAnswers => {
  switch (action.type) {
    case actions.PATCH_ANSWERS_STATE: {
      const { answersState } = action;
      return { ...state, ...answersState };
    }
    case actions.ADD_OR_UPDATE_SKILLS_ANSWER: {
      const { answer } = action;
      return {
        ...state,
        skills: state.skills.find(item => item.questionId === answer.questionId)
          ? state.skills.map(item => item.questionId === answer.questionId ? answer : item)
          : [...state.skills, answer]
      };
    }
    default: {
      return state;
    }
  }
};

export default AnswersReducer;
