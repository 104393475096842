import template from './template';

const locale: typeof template = {
  COMMONS: {
    ERRORS: {
      ACCESS: 'Vos identifiants sont incorrects ou n’existent pas, veuillez vous authentifier sur le site jeunes d’avenir.',
    },
    LEGENDS: {
      LEGEND_3: 'Très souvent',
      LEGEND_2: 'Souvent',
      LEGEND_1: 'Parfois',
      LEGEND_0: 'Rarement'
    }
  },
  COMPONENTS: {
    SLIDE_QUESTION: {
      FROM: 'De :',
    },
    DOWNLOAD_PDF: {
      LABEL: 'Télécharger mon profil',
    },
    MODAL_RESET: {
      LABEL: 'Recommencer le quiz',
      TITLE: 'Recommencer le quiz',
      TEXT: 'Attention, les résultats de votre profil actuel seront supprimés.',
      CONFIRM: 'Confirmer',
      CANCEL: 'Annuler'
    },
    PIWIK_PRO_PRIVACY: {
      TEXT: 'Nous collectons et traitons vos données sur ce site afin de mieux comprendre comment elles sont utilisées. Vous pouvez choisir de participer ou de ne pas participer à tout moment.',
      OPTED_IN: 'Vous acceptez la collecte et l’utilisation de vos données',
      OPTED_OUT: 'Vous refusez la collecte et l’utilisation de vos données',
    }
  },
  PAGES: {
    HOME: {
      HELLO: 'Bonjour !',
      TITLE: 'Bienvenue dans ce quiz pour apprendre à mieux te connaître.',
      TEXT: 'Nous te proposons de découvrir <b>tes compétences</b> en répondant à certaines questions.',
      TIME: '10 minutes',
      BUTTON: 'C\'est parti !'
    },
    PROFILE: {
      TITLE: 'Mon profil',
      TEXT_SCREEN: 'Les résultats représentent l’évaluation de la fréquence à laquelle tu mets en oeuvre les compétences concernées.',
      TEXT_PRINT: 'Les résultats représentent l’évaluation de la fréquence à laquelle le candidat met en œuvre les compétences concernées.',
    },
    SKILLS: {
      TITLE: 'Révèle tes compétences !',
    }
  },
};

export default locale;
