import profileDefault from './profile/profile.default';
import answersDefault from './answers/answers.default';
import configDefault from './config/config.default';
import authDefault from './auth/auth.default';
import { IStore } from './store.model';

const storeDefault: IStore = {
  profile: profileDefault,
  answers: answersDefault,
  config: configDefault,
  auth: authDefault,
};

export default storeDefault;
