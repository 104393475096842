import { PatchProfileSkills } from 'core/store/profile/profile.actions';
import useScopedTranslation from 'core/hooks/use-scoped-translation';
import { deleteProfileSkills } from 'core/domain/api.domain';
import { fade, fadeScale } from './ModalReset.transitions';
import { AuthState } from 'core/store/auth/auth.selectors';
import { Dialog, Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import React, { Fragment, useState } from 'react';
import useBoolean from 'core/hooks/use-boolean';
import styles from './ModalReset.module.css';
import toast from 'react-hot-toast';
import Loader from 'lib/Loader';
import { PatchAnswersState } from '../../core/store/answers/answers.actions';

const ModalReset = React.forwardRef<HTMLDivElement>((_, ref) => {
  const { t } = useScopedTranslation('COMPONENTS', 'MODAL_RESET');
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useBoolean();
  const { id } = useSelector(AuthState);
  const dispatch = useDispatch();

  const handleCancel = () => {
    if (loading) return;
    setIsOpen.off();
  };

  const handleConfirm = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await deleteProfileSkills(id!);
      dispatch(PatchAnswersState({ skills: [] }));
      dispatch(PatchProfileSkills({ ended: false, items: [] }));
    } catch (error) {
      console.error(error);
      toast.error('Une erreur s\'est produite');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <div className={styles.label} onClick={setIsOpen.on}>{t('LABEL')}</div>
      <Transition appear={false} show={isOpen} as={Fragment}>
        <Dialog ref={ref} as='div' className={styles.root} onClose={() => null}>
          <Transition.Child as={Fragment} {...fade}>
            <div className={styles.overlay} />
          </Transition.Child>

          <div className={styles.wrapper}>
            <div className={styles.container}>
              <Transition.Child as={Fragment}{...fadeScale}>
                <Dialog.Panel className={styles.panel}>
                  <Dialog.Title className={styles.title}>{t('TITLE')}</Dialog.Title>
                  <p className={styles.text}>{t('TEXT')}</p>
                  <div className={`${styles.buttons} ${loading ? styles.loading : ''}`}>
                    <button className={styles.cancel} onClick={handleCancel}>{t('CANCEL')}</button>
                    <button className={styles.confirm} onClick={handleConfirm}>
                      {t('CONFIRM')} <Loader className={styles.loader} size='xs' white/>
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Fragment>
  );
});

export default ModalReset;
