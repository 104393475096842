import React from 'react';
import styles from './ProfileRow.module.css';
import Score from './Score';

interface Props {
  readonly id: string;
  readonly label: string;
  readonly percentage: number;
  readonly scores: {
    readonly id: string;
    readonly label: string;
    readonly threshold: number;
    readonly description: string;
  }[];
  readonly className: string;
}

const ProfileRow: React.FC<Props> = ({ label, percentage, scores, className }) => {
  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.background}/>
      <div className={styles.head}>
        <div className={styles.lines}>
          <div />
          <div />
          <div />
        </div>
        <h4 className={styles.title}>{label}</h4>
        <h3>{Math.round(percentage)}%</h3>
      </div>
      <div className={styles.scores}>
        {scores.map((score, i) => <Score {...score} key={i} />)}
      </div>
    </div>
  );
};

export default ProfileRow;
