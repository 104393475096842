import { GET_PROFILE_STATE, PatchProfileError, PatchProfileState } from './profile.actions';
import { put, takeLatest, select } from 'redux-saga/effects';
import { GetConfigState } from '../config/config.actions';
import { getProfile } from 'core/domain/api.domain';
import { AuthState } from '../auth/auth.selectors';
import { IAuth } from '../auth/auth.model';

export function* GetProfileStateSaga() {
  try {
    const { id, signature }: IAuth = yield select(AuthState);
    const { data } = yield getProfile(id!, signature!);
    yield put(PatchProfileState({ ...data, fetched: true }));
    if (!data.skills.ended) yield put(GetConfigState());
    else if (!data.skills.items.length) yield put(PatchProfileError(new Error()));
  } catch (e) {
    yield put(PatchProfileError(e as Error));
  }
}

export function* ProfileWatcher() {
  yield takeLatest(GET_PROFILE_STATE, GetProfileStateSaga);
}
