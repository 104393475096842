import useScopedTranslation from 'core/hooks/use-scoped-translation';
import { AuthState } from 'core/store/auth/auth.selectors';
import { downloadBlob } from 'core/helpers/blob.helpers';
import { encode64 } from 'core/helpers/strings.helpers';
import { getPdf } from 'core/domain/api.domain';
import styles from './DownloadPdf.module.css';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { Download } from 'lib/Icons';
import Loader from 'lib/Loader';
import toast from "react-hot-toast";

const DownloadPdf: React.FC = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const {id, firstname, lastname} = useSelector(AuthState);
  const { t } = useScopedTranslation('COMPONENTS', 'DOWNLOAD_PDF');

  const onClick = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const {data} = await getPdf(id!, encode64(firstname!), encode64(lastname!));
      downloadBlob(data, firstname!, lastname!);
    } catch (error) {
      console.error(error);
      toast.error('Une erreur s\'est produite');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      onClick={onClick}
      className={`${styles.root} ${loading ? styles.loading : ''}`}
    >
      {loading ? <Loader size='xs'/> : <Download/>}
      <span>{t('LABEL')}</span>
    </div>
  );
};

export default DownloadPdf;
