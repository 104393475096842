import styles from './Container.module.css';
import React from 'react';

interface Props {
  readonly className?: string;
}

const Container: React.FC<Props> = ({className, children}) => {
  return (
    <div className={`${styles.root} ${className}`}>
      {children}
    </div>
  );
};

export default Container;
