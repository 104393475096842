import { Toaster } from 'react-hot-toast';
import Routes from './Routes';
import React from 'react';

const App = () => {
  return (
    <main className="w-full min-h-full overflow-x-hidden">
      <Routes />
      <Toaster />
    </main>
  );
};

export default App;
