import { BrowserRouter } from 'react-router-dom';
import PiwikPro from '@piwikpro/react-piwik-pro';
import Initializer from './Initializer';
import ReactDOM from 'react-dom';
import 'assets/styles/main.css';
import React from 'react';
import App from './App';

const environment = process.env.REACT_APP_ENV;
const containerId = process.env.REACT_APP_PIWIKPRO_CONTAINER_ID;
const containerUrl = process.env.REACT_APP_PIWIKPRO_CONTAINER_URL;
if (containerId && containerUrl && environment === 'production') {
  PiwikPro.initialize(containerId, containerUrl);
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Initializer>
        <App />
      </Initializer>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
