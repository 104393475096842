import styles from './AnimatedPage.module.css';
import { motion } from 'framer-motion';
import React from 'react';

const AnimatedPage: React.FC = ({ children }) => {
  return (
    <motion.div
      transition={{duration: .5}}
      className={styles.root}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedPage;
