import { PatchAnswersState, SEND_SKILLS_ANSWERS } from './answers.actions';
import { PatchProfileSkills } from '../profile/profile.actions';
import { put, takeLatest, select } from 'redux-saga/effects';
import { putProfileSkills } from 'core/domain/api.domain';
import { AnswersSkills } from './answers.selectors';
import { AuthState } from '../auth/auth.selectors';
import { IAnswer } from 'core/models/answer.model';
import { IAuth } from '../auth/auth.model';
import toast from 'react-hot-toast';

export function* SendSkillsAnswersSaga() {
  try {
    yield put(PatchAnswersState({sending: true}));
    const { id }: IAuth = yield select(AuthState);
    const answers: IAnswer[] = yield select(AnswersSkills);
    const { data } = yield putProfileSkills(id!, { answers }!);
    yield put(PatchProfileSkills({ ended: true, items: data.items }));
  } catch (e) {
    console.log(e);
    toast.error('Une erreur s\'est produite');
  } finally {
    yield put(PatchAnswersState({sending: false}));
  }
}

export function* AnswersWatcher() {
  yield takeLatest(SEND_SKILLS_ANSWERS, SendSkillsAnswersSaga);
}
