import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import { applyMiddleware, createStore } from 'redux';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import StoreReducer from './store.reducer';
import { storeSagas } from './store.sagas';

const createStoreWithKey = (key: string) => {

  const persistConfig = {
    key: process.env.REACT_APP_STORE_KEY + key,
    whitelist: ['answers'],
    storage,
  };

  const sagaMiddleware = createSagaMiddleware();

  const middlewares: any[] = [sagaMiddleware];

  if (process.env.NODE_ENV !== 'production') {
    const { createLogger } = require('redux-logger');
    const logger = createLogger({collapsed: true, duration: true});
    middlewares.push(logger);
  }

  const store = createStore(
    persistReducer(persistConfig, StoreReducer),
    composeWithDevTools(applyMiddleware(...middlewares))
  );

  const persist = persistStore(store);

  sagaMiddleware.run(storeSagas);

  return { store, persist }
};

export default createStoreWithKey;
