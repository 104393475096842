import { initReactI18next } from 'react-i18next';
import fr from './locales/fr';
import i18n from 'i18next';

export type LanguagesAvailable = 'fr';

i18n.on('languageChanged', (lng: LanguagesAvailable) => {
  document.documentElement.setAttribute('lang', lng);
});

export const resources = { fr };

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'fr',
    interpolation: {
      escapeValue: false,
    },
  });

export const getCurrentLanguage = (): LanguagesAvailable => i18n.languages[0] as LanguagesAvailable;

export default i18n;
