import { AnswersWatcher } from './answers/answers.sagas';
import { ProfileWatcher } from './profile/profile.sagas';
import { ConfigWatcher } from './config/config.sagas';
import { all, fork } from 'redux-saga/effects';

export function* storeSagas() {
  yield all([
    fork(AnswersWatcher),
    fork(ProfileWatcher),
    fork(ConfigWatcher),
  ]);
}
