import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import 'assets/styles/animations.css';
import Profile from 'pages/Profile';
import Skills from 'pages/Skills';
import Home from 'pages/Home';
import React from 'react';

const AppRoutes: React.FC = () => {

  const location = useLocation();
  const { search } = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes key={location.pathname} location={location}>
        <Route index element={<Home />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/*" element={<Navigate to={'/' + search} />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AppRoutes;
