import { IAnswer } from 'core/models/answer.model';
import axios, { AxiosResponse } from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getProfile = (id: string, signature: string): Promise<any> => {
  return axios.get(API_URL + `/users/${id}/profile?signature=${encodeURIComponent(signature)}`);
};

export const getConfigurations = (id: string): Promise<any> => {
  return axios.get(API_URL + `/users/${id}/configurations`);
};

export const getPdf = (id: string, firstName: string, lastName: string): Promise<AxiosResponse> => {
  return axios.get(API_URL + `/pdfs/${id}?firstName=${firstName}&lastName=${lastName}`,
    {responseType: 'blob', headers: {Accept: 'application/pdf'}});
};

export const putProfileSkills = (id: string, body: { answers: IAnswer[] }): Promise<any> => {
  return axios.put(API_URL + `/users/${id}/profile/skills`, body);
};

export const deleteProfileSkills = (id: string): Promise<any> => {
  return axios.delete(API_URL + `/users/${id}/profile/skills`);
}
