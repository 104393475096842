import ProfileReducer from './profile/profile.reducer';
import AnswersReducer from './answers/answers.reducer';
import ConfigReducer from './config/configReducer';
import { combineReducers, AnyAction } from 'redux';
import AuthReducer from './auth/auth.reducer';
import storeDefault from './store.default';
import * as actions from './store.actions';
import { IStore } from './store.model';

const reducers = combineReducers<IStore>({
  profile: ProfileReducer,
  answers: AnswersReducer,
  config: ConfigReducer,
  auth: AuthReducer,
});

const StoreReducer = (state: IStore = storeDefault, action: AnyAction): IStore => {
  switch (action.type) {
    case actions.RESET_STORE: {
      return reducers(storeDefault, action);
    }
    default: {
      return reducers(state, action);
    }
  }
};

export default StoreReducer;
