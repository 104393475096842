import { IProfile, IProfileSkills } from './profile.model';

export const GET_PROFILE_STATE = 'GET_PROFILE_STATE';
export const PATCH_PROFILE_STATE = 'PATCH_PROFILE_STATE';
export const PATCH_PROFILE_SKILLS = 'PATCH_PROFILE_SKILLS';
export const PATCH_PROFILE_ERROR = 'PATCH_PROFILE_ERROR';


export const GetProfileState = () => ({
  type: GET_PROFILE_STATE,
});

export const PatchProfileState = (profileState: Partial<IProfile>) => ({
  type: PATCH_PROFILE_STATE,
  profileState,
});

export const PatchProfileSkills = (skills: Partial<IProfileSkills>) => ({
  type: PATCH_PROFILE_SKILLS,
  skills,
});

export const PatchProfileError = (error: Error) => ({
  type: PATCH_PROFILE_ERROR,
  error,
});
