import { AuthState } from 'core/store/auth/auth.selectors';
import { CustomEvent } from '@piwikpro/react-piwik-pro'
import { useSelector } from 'react-redux';

const useTracker = () => {

  const { id } = useSelector(AuthState);

  const trackHome = () => {
    if (!id) return;
    CustomEvent.trackEvent('pages', 'home', id);
  };

  const trackProfile = () => {
    if (!id) return;
    CustomEvent.trackEvent('pages', 'profil', id);
  };

  const trackTooltip = (name: string) => {
    if (!id) return;
    CustomEvent.trackEvent('tooltip', name, id);
  };

  const trackProgression = (progression: string) => {
    if (!id) return;
    CustomEvent.trackEvent('progression', progression, id);
  };

  return { trackHome, trackProfile, trackProgression, trackTooltip };
};

export default useTracker;
