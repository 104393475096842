import styles from './Error.module.css';
import React from 'react';

interface Props {
  readonly title: string;
}

const Error: React.FC<Props> = ({ title }) => {
  return (
    <div className={styles.root}>
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
           className="iconify iconify--ph" width="32" height="32" preserveAspectRatio="xMidYMid meet"
           viewBox="0 0 256 256">
        <path fill="currentColor"
              d="M116 136v-32a12 12 0 0 1 24 0v32a12 12 0 0 1-24 0Zm124.2 78a27.5 27.5 0 0 1-24.2 14H40a28 28 0 0 1-24.2-42l88-152a27.9 27.9 0 0 1 48.4 0l88 152a27.5 27.5 0 0 1 0 28Zm-20.8-16L131.5 46a4.1 4.1 0 0 0-7 0L36.6 198a3.8 3.8 0 0 0 0 4a3.8 3.8 0 0 0 3.4 2h176a3.8 3.8 0 0 0 3.4-2a3.8 3.8 0 0 0 0-4ZM128 160a16 16 0 1 0 16 16a16 16 0 0 0-16-16Z" />
      </svg>
      <span className={styles.title}>{title}</span>
    </div>
  );
};

export default Error;
