import { AddOrUpdateSkillsAnswer, SendSkillsAnswers } from 'core/store/answers/answers.actions';
import React, { CSSProperties, ReactElement, useEffect, useRef, useState } from 'react';
import { AnswersSending, AnswersSkills } from 'core/store/answers/answers.selectors';
import { useLocation, useNavigate } from 'react-router-dom';
import { shuffleArray } from 'core/helpers/arrays.helpers';
import { useDispatch, useSelector } from 'react-redux';
import { IAnswer } from 'core/models/answer.model';
import { ITopic } from 'core/models/topic.model';
import { Question, Topic } from '../index';
import styles from './Wrapper.module.css';
import Container from 'lib/Container';
import Slide from 'components/Slide';
import { Back } from 'lib/Icons';
import Button from 'lib/Button';
import Loader from 'lib/Loader';
import useTracker from '../../../core/hooks/use-tracker';

interface Props {
  readonly topics: ITopic[];
  readonly answers: IAnswer[];
}

const Wrapper: React.FC<Props> = ({ topics, answers }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { trackProgression } = useTracker();
  const skills = useSelector(AnswersSkills);
  const sending = useSelector(AnswersSending);
  const totalSkillsAnswers = useRef<number>(skills.length);

  const [current, setCurrent] = useState<number>(0);
  const [reversed, setReversed] = useState<boolean>(false);
  const [elements, setElements] = useState<ReactElement[]>([]);

  const currentElementId = elements[current]?.props.id;

  const onClickOption = (answer: IAnswer) => {
    dispatch(AddOrUpdateSkillsAnswer(answer));
  };

  const onClickNext = () => {
    if (!canGoNext()) return;
    setReversed(false);
    current >= elements.length - 1
      ? dispatch(SendSkillsAnswers())
      : setCurrent(state => state + 1);
  };

  const onClickPrev = () => {
    setReversed(true);
    canGoBack()
      ? setCurrent(state => state - 1)
      : navigate('/' + search);
  };

  const canGoBack = () => {
    return current > 0;
  };

  const canGoNext = () => {
    if (sending) return false;
    if (!currentElementId) return true;
    return !!getAnswer(currentElementId);
  };

  const getAnswer = (questionId?: string): { answer: IAnswer } | undefined => {
    if (!questionId) return;
    const answer = answers.find(item => item.questionId === questionId);
    return answer ? { answer } : undefined;
  };

  const getProgress = (): string => {
    return ((current / (elements.length - 1) * 100) || 0) + '%';
  };

  useEffect(() => {
    if (skills.length > totalSkillsAnswers.current) {
      totalSkillsAnswers.current = skills.length;
      trackProgression(getProgress());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skills]);

  useEffect(() => {
    const elements = topics.map((topic) => {
      return [
        <Topic label={topic.label} imageUrl={topic.imageUrl} />,
        ...topic.questions.map(question => {
          const options = shuffleArray(question.options);
          return <Question {...question} options={options} topicLabel={topic.label} onClick={onClickOption} />;
        })
      ];
    }).flat();
    setElements(elements);
  }, [topics]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!answers.length) return;

    const readTopics = topics.reduce((acc, cur, i, arr) => {
      const t = arr.slice(0, i).reduce((a, c) => a + c.questions.length, 0);
      return t > answers.length ? acc : acc + 1;
    }, 0);

    setCurrent(answers.length + readTopics - 1);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className={styles.root}>
        <Container className={styles.container}>
          {elements.map((element, i) => (
            <Slide
              key={i}
              reversed={reversed}
              show={current === i}
              className="w-full h-full"
            >
              {React.cloneElement(element, getAnswer(element.props.id))}
            </Slide>
          ))}
        </Container>
      </div>

      <Button variant="left" onClick={onClickPrev}><Back className="w-5 h-5" /></Button>
      <Button variant="right" disabled={!canGoNext()} onClick={onClickNext} loading={sending}
              loader={<Loader size="sm" white />}>Suivant</Button>

      <div className={styles.progress} style={{ '--progress': getProgress() } as CSSProperties}>
        <div className={styles.bar} />
      </div>
    </>
  );
};

export default Wrapper;
