import { IAnswer } from 'core/models/answer.model';
import { IStore } from '../store.model';

export const AnswersSkills = (store: IStore): IAnswer[] => {
  return store.answers.skills;
};

export const AnswersSending = (store: IStore): boolean => {
  return store.answers.sending;
};
