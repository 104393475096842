import { Transition } from '@headlessui/react';
import styles from './Slide.module.css';
import React from 'react';

interface Props {
  readonly show: boolean;
  readonly reversed: boolean;
  readonly className?: string;
}

const Slide: React.FC<Props> = ({ show, reversed, className, children }) => {

  return (
    <Transition
      show={show}
      appear={false}
      className={`${styles.root} ${className}`}
      enter="transform transition-all ease-linear opacity-0 duration-300 delay-300"
      leave="transform transition-all ease-linear opacity-0 duration-300"
      enterFrom={reversed ? '-translate-x-20' : 'translate-x-20'}
      leaveTo={reversed ? 'translate-x-20' : '-translate-x-20'}
      leaveFrom="translate-x-0 opacity-100"
      enterTo="translate-x-0  opacity-100"
    >
      {children}
    </Transition>
  );
};

export default Slide;
