import useScopedTranslation from 'core/hooks/use-scoped-translation';
import styles from './Legends.module.css';
import React from 'react';

const Legends: React.FC = () => {

  const {t} = useScopedTranslation('COMMONS', 'LEGENDS');

  return (
    <div className={styles.root}>
      <div className={styles.item}>{t('LEGEND_3')}</div>
      <div className={styles.item}>{t('LEGEND_2')}</div>
      <div className={styles.item}>{t('LEGEND_1')}</div>
      <div className={styles.item}>{t('LEGEND_0')}</div>
    </div>
  );
};

export default Legends;
