import styles from './Option.module.css';
import React from 'react';

interface Props {
  readonly id: string;
  readonly label: string;
  readonly selected?: boolean;
  readonly onSelect?: () => void;
}

const Option: React.FC<Props> = ({ id, label, selected, onSelect }) => {
  return (
    <div key={id} onClick={onSelect} className={`${styles.root} ${selected ? styles.selected : ''}`}>
      <h6>{label}</h6>
    </div>
  );
};

export default Option;
